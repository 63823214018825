/* .video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
} */

.video-container {
    position: relative; /* Required for aspect ratio */

    width: 90vw;
    margin: 0 auto;
    height: 0; /* Set height to 0 and use padding-bottom trick */
    padding-bottom: 56.25%; /* 16:9 aspect ratio (adjust if needed) */
  }
  
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  